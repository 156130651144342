import { default as classNames, default as cx } from "classnames";
import RenderError from "components/RenderError";
import { getCookie, setSidebarCookie } from "globals/helpers/cookieHelper";
import AppErrorBoundary from "hoc/AppErrorBoundary";
import {
    useSessionBusiness,
} from "hooks/general/appContextHelpers";
import { useCheckPermission } from "hooks/permissionCheck";
import { FeatureNotAvailableOverlay } from "layouts/appLayout/partials";
import { ERROR_CODE_PARAM_KEY } from "layouts/ErrorLayout";
import { defaultTo } from "lodash-es";
import { BaseObject, CookiesKey } from "models/general";
import React, {
    PropsWithChildren,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useResizeDetector } from "react-resize-detector/build/withPolyfill";
import { Outlet, useSearchParams } from "react-router-dom";
import { AppHeader } from "./partials/header";
import { AppSidebar } from "./partials/sidebar";
import { AppLayoutBase } from "./types";

interface AppLayoutState extends AppLayoutBase {}

export const AppLayout = React.memo(
    ({ children }: PropsWithChildren<BaseObject>) => {
        const isSideBarOpen: boolean =
            getCookie(CookiesKey.IS_SIDEBAR_OPEN) === "true";

        const [searchParams] = useSearchParams();
        const { width: targetWidth, ref } = useResizeDetector();
        const { isContractExpired } = useSessionBusiness();
        const { moduleAccess } = useCheckPermission();

        const hasError = useMemo(() => {
            const errorCode = searchParams.get(ERROR_CODE_PARAM_KEY);
            return !!errorCode;
        }, [searchParams]);

        const scrollContainerRef = useRef<any>(null);
        const [state, setState] = useState<AppLayoutState>({
            enableClosedSidebar: isSideBarOpen,
            enableMobileMenu: false,
            enableMobileMenuRight: false,
            setEnableClosedSidebar: (value: boolean) => {
                setSidebarCookie(value);
                setState((old) => {
                    return { ...old, enableClosedSidebar: value };
                });
            },
            setEnableMobileMenu: (value: boolean) => {
                setState((old) => {
                    return { ...old, enableMobileMenu: value };
                });
            },
            setEnableMobileMenuRight: (value: boolean) => {
                setState((old) => {
                    return { ...old, enableMobileMenuRight: value };
                });
            },
        });

        useEffect(() => {
            if (targetWidth) {
                if (targetWidth > 1250) {
                    setState((old) => {
                        const newVal =
                            getCookie(CookiesKey.IS_SIDEBAR_OPEN) === "true";
                        return old.enableClosedSidebar != newVal
                            ? {
                                  ...old,
                                  enableClosedSidebar:
                                      getCookie(CookiesKey.IS_SIDEBAR_OPEN) ===
                                      "true",
                              }
                            : old;
                    });
                } else if (targetWidth < 1250) {
                    setState((old) => ({
                        ...old,
                        enableClosedSidebar: true,
                    }));
                }
            }
            if (scrollContainerRef.current) {
                // to update scrollbar properly
                scrollContainerRef.current.updateScroll();
            }
        }, [targetWidth]);

        return hasError ? (
            <></>
        ) : (
            <div
                className={cx(
                    `app-container app-theme-white fixed-header`,
                    {
                        "fixed-sidebar": state.enableClosedSidebar,
                    },
                    {
                        "closed-sidebar": state.enableClosedSidebar,
                    },
                    { "sidebar-mobile-open": state.enableMobileMenu }
                )}
            >
                <AppHeader {...state} />
                <div className="app-main" ref={ref}>
                    {!isContractExpired && <AppSidebar />}

                    <div
                        className={classNames("app-main__outer", {
                            ["full-width"]: isContractExpired,
                        })}
                    >
                        <div className="app-main__inner">
                            {!moduleAccess.hasAccess &&
                                moduleAccess.showOnRoot && (
                                    <FeatureNotAvailableOverlay />
                                )}
                            <div className={"right-content"}>
                                <AppErrorBoundary TargetComponent={RenderError}>
                                    {children ? children : <Outlet />}
                                </AppErrorBoundary>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default AppLayout;
