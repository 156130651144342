import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppIconWithName, AppTooltip } from "components";
import { AppButton, AppCollapseToggleButton } from "components/Buttons";
import { AppTable } from "components/Table";
import { FOUR_PRECISION_NUMERIC } from "globals/constants";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { getFormattedTimeFromNumber } from "globals/helpers/localizationHelpers";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { defaultTo, isNil } from "lodash-es";
import { SelectItem, UserType } from "models/general";
import {
    AccessPointAction,
    ControllerType,
    ManualTimeTrackingEntryRequest,
    TimeTrackingHeaderData,
    TimeTrackingRecord,
} from "models/timeManagement";
import moment from "moment";
import { Moment } from "moment-timezone";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
    TimeTrackingService,
    getTimeTrackingServiceKey,
} from "services/business";
import { TimeManagementAvailableStaffPopper } from "../poppers";
import { TimeManagementClientInfoTags } from "../TimeManagementClientInfoTags";
import { TimeManagementColorHighlight } from "../TimeManagementColorHighlight";
import { TimeManagementInOutSummary } from "../TimeManagementInOutSummary";
import styles from "./TimeManagementDayTable.module.scss";

export interface TimeManagementDayTableProps {
    headerData: TimeTrackingHeaderData;
    record: TimeTrackingRecord[];
    isTableToggle?: boolean;
    forGroup?: SelectItem;
    date: Moment;
    defaultToggleValue?: boolean;
    className?: string;
    isEmpty?: boolean;
    manualAllowed: boolean;
    refetchData?: () => void;
    emptyString?: string;
    visible: boolean;
    tableToggle: () => void;
    onClick: (val: number) => void;
}

export const TimeManagementDayTable: React.FC<TimeManagementDayTableProps> = ({
    headerData,
    forGroup,
    record,
    isTableToggle = true,
    onClick,
    manualAllowed,
    date,
    refetchData,
    visible,
    className = "",
    tableToggle,
    isEmpty = record.length <= 0,
    emptyString,
}) => {
    const { t } = useTranslation();
    const { formatNumber, formatDate } = useLocaleHelpers();
    emptyString = defaultTo(emptyString, t("common.noDataFound"));
    const { sessionBusiness } = useSessionBusiness();
    const { linkProvider } = useRouting();
    const timeTrackingService = new TimeTrackingService(
        linkProvider.business.api.timeTracking
    );

    const {
        isLoading: updating,
        mutate: addNewEntry,
        data: response,
        reset,
    } = useMutation(
        getTimeTrackingServiceKey("addNewEntry"),
        async (params: ManualTimeTrackingEntryRequest) => {
            return await timeTrackingService.addNewEntry(params);
        }
    );

    useEffect(() => {
        if (!updating && response) {
            if (response && response.Data) {
                if (refetchData) {
                    refetchData();
                }
                showSweetAlertToast(
                    t("common.success"),
                    t(`timeManagement.timeEntryAddedSuccessfully`),
                    "success"
                );
            } else {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("timeManagement.manualEntryError"),
                    "error"
                );
            }
            reset();
        }
    }, [response, updating]);

    const showManualButton =
        manualAllowed && formatDate(date) == formatDate(moment());
    const maxColSpan = showManualButton ? 12 : 11;
    return (
        <AppTable
            hover={true}
            containerClass={`${styles.root} ${className}`}
            collapsed={!visible}
            useMaxHeight={false}
        >
            <thead
                onClick={() => tableToggle()}
                className={styles.tableHeading}
            >
                <tr>
                    <th
                        style={{
                            minWidth: "370px",
                            textAlign: "start",
                        }}
                        colSpan={2}
                    >
                        <AppIconWithName
                            showIcon={false}
                            className={styles.iconName}
                            text={defaultTo(forGroup && forGroup.Text, "")}
                        />
                    </th>
                    {showManualButton && (
                        <th style={getFixedCssWidths(40)}></th>
                    )}
                    <th
                        className={
                            headerData.Totals.IN != 0 ? styles.textBlue : ""
                        }
                        style={{
                            minWidth: "140px",
                            width: "140px",
                            textAlign: "center",
                        }}
                    >
                        <span
                            style={{
                                display: "inline-flex",
                                marginLeft: "-10px",
                            }}
                        >
                            {headerData.Totals.IN && headerData.Totals.IN}
                        </span>
                    </th>
                    <th
                        style={{
                            minWidth: "140px",
                            width: "140px",
                            textAlign: "center",
                        }}
                        className={
                            headerData.Totals.OUT != 0 ? styles.textRed : ""
                        }
                    >
                        <span
                            style={{
                                display: "inline-flex",
                                marginLeft: "-5px",
                            }}
                        >
                            {headerData.Totals.OUT && headerData.Totals.OUT}
                        </span>
                    </th>
                    <th
                        style={{
                            minWidth: "200px",
                            width: "200px",
                            textAlign: "center",
                        }}
                        className={styles.textGrey}
                    >
                        {getFormattedTimeFromNumber(
                            headerData.Totals.CareHours
                        )}
                    </th>
                    <th
                        style={{
                            minWidth: "200px",
                            width: "200px",
                            textAlign: "center",
                        }}
                        className={styles.textGrey}
                    >
                        {getFormattedTimeFromNumber(
                            headerData.Totals.MaxCareHours
                        )}
                    </th>
                    <th
                        style={{ minWidth: "200px", width: "200px" }}
                        className={styles.textGrey}
                    >
                        <TimeManagementColorHighlight
                            differenceSumMinute={
                                headerData.Totals.SumDifference
                            }
                            isNegativeRed={false}
                        />
                    </th>
                    <th
                        style={{
                            minWidth: "180px",
                            width: "180px",
                            textAlign: "center",
                        }}
                        className={styles.textGrey}
                    >
                        {formatNumber(
                            headerData.Totals.NeededEmployees,
                            FOUR_PRECISION_NUMERIC
                        )}
                    </th>
                    <th style={{ minWidth: "180px", width: "180px" }}>
                        <div className={styles.availableStaffHeaderDiv}>
                            <span className={` ${styles.textGrey}`}>
                                {formatNumber(
                                    headerData.Totals.AvailableStaff,
                                    FOUR_PRECISION_NUMERIC
                                )}
                            </span>
                            <div className={styles.availableStaffIconDiv}>
                                {headerData.ActiveEmployees && (
                                    <TimeManagementAvailableStaffPopper
                                        activeEmployees={headerData.ActiveEmployees.filter(
                                            (x) =>
                                                !forGroup ||
                                                x.GroupId === forGroup.Value
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                    </th>
                    <th style={{ width: "160px", minWidth: "160px" }}>
                        <TimeManagementColorHighlight
                            differenceSumMinute={headerData.Totals.InfoTotal}
                            isTime={false}
                        />
                    </th>
                    <th style={{ width: "190px", minWidth: "190px" }}>
                        <AppCollapseToggleButton visible={visible} />
                    </th>
                </tr>
            </thead>
            <tbody
                className={
                    visible ? styles.displayTableRow : styles.displayNone
                }
            >
                <tr className="dummy-row">
                    <td colSpan={maxColSpan} />
                </tr>
                <tr className="dummy-row">
                    <td colSpan={maxColSpan} />
                </tr>
                {isEmpty ? (
                    <tr>
                        <td colSpan={maxColSpan}>
                            <div
                                style={{
                                    fontSize: "1rem",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                {emptyString}
                            </div>
                        </td>
                    </tr>
                ) : (
                    record.map((x) => {
                        const isOut = isNil(x.LastExitTime)
                            ? isNil(x.CurrentAction) || // if no out entry then use CurrentAction for clients as no need to consider ignored entries here
                              x.CurrentAction == AccessPointAction.OUT
                            : isNil(x.LastAction) ||
                              x.LastAction == AccessPointAction.OUT;
                        return (
                            <tr
                                style={{ height: "48px" }}
                                key={x.PersonId}
                                onClick={() => onClick(x.PersonId)}
                            >
                                <td colSpan={2}>
                                    <AppIconWithName
                                        text={x.PersonName}
                                        className={styles.iconName}
                                    />
                                </td>
                                {showManualButton && (
                                    <td>
                                        <AppTooltip
                                            content={t(
                                                `timeManagement.${
                                                    isOut
                                                        ? "startTimer"
                                                        : "stopTimer"
                                                }`
                                            )}
                                            allowHtml={false}
                                            trigger="mouseenter focus"
                                            arrow={true}
                                        >
                                            <AppButton
                                                className={`${styles.manualButton} rounded-circle`}
                                                colorVariant={
                                                    isOut ? "blue" : "red"
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    addNewEntry({
                                                        EpochInstant:
                                                            moment().unix(),
                                                        PersonId: x.PersonId,
                                                        AccessControlId:
                                                            x.AccessId,
                                                        BusinessId:
                                                            sessionBusiness?.Id as number,
                                                        Type: ControllerType.Main,
                                                        PersonType:
                                                            UserType.Client,
                                                    });
                                                }}
                                                disabled={updating}
                                            >
                                                <FontAwesomeIcon
                                                    style={
                                                        isOut
                                                            ? {
                                                                  marginLeft:
                                                                      "3px",
                                                              } // to center play button
                                                            : undefined
                                                    }
                                                    icon={
                                                        isOut ? "play" : "stop"
                                                    }
                                                />
                                            </AppButton>
                                        </AppTooltip>
                                    </td>
                                )}
                                <td colSpan={2}>
                                    <TimeManagementInOutSummary
                                        start={x.FirstEntranceTime}
                                        end={x.LastExitTime}
                                        completedTime={x.CareMinutes}
                                        maxTime={x.MaxContractMinutes}
                                        accessControlId={x.AccessId as number}
                                    />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    {getFormattedTimeFromNumber(x.CareMinutes)}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    {getFormattedTimeFromNumber(
                                        x.MaxContractMinutes
                                    )}
                                </td>
                                <td>
                                    <TimeManagementColorHighlight
                                        differenceSumMinute={
                                            !x.IgnoreDifference &&
                                            (x.LastExitTime ||
                                                x.FirstEntranceTime)
                                                ? x.DifferenceSumMinutes
                                                : null
                                        }
                                        isNegativeRed={false}
                                    />
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    {formatNumber(
                                        x.NeededEmployees,
                                        FOUR_PRECISION_NUMERIC
                                    )}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    {x.CurrentAction}
                                </td>
                                <td colSpan={2}>
                                    <TimeManagementClientInfoTags
                                        values={x.MedicalInfo}
                                        personId={x.PersonId}
                                        dayType={x.DateType}
                                    />
                                </td>
                            </tr>
                        );
                    })
                )}
                <tr className="dummy-row">
                    <td colSpan={maxColSpan} />
                </tr>
            </tbody>
        </AppTable>
    );
};

export default TimeManagementDayTable;
